(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/navigation-menu-v2/views/navigation.js') >= 0) return;  svs.modules.push('/components/components/navigation-menu-v2/views/navigation.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_cmps=_svs.components=_svs.components||{};
_cmps.navigation_menu_v2=_cmps.navigation_menu_v2||{};
_cmps.navigation_menu_v2.templates=_cmps.navigation_menu_v2.templates||{};
svs.components.navigation_menu_v2.templates.navigation = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"hideDimmer") : depth0),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":2},"end":{"line":7,"column":13}}})) != null ? stack1 : "")
    + "  <div class=\"js-nav js-nav-menu"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"navigation-menu") : depth0)) != null ? lookupProperty(stack1,"branding") : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":32},"end":{"line":8,"column":109}}})) != null ? stack1 : "")
    + " ";
  stack1 = ((helper = (helper = lookupProperty(helpers,"isAnonymous") || (depth0 != null ? lookupProperty(depth0,"isAnonymous") : depth0)) != null ? helper : alias2),(options={"name":"isAnonymous","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":8,"column":110},"end":{"line":8,"column":177}}}),(typeof helper === "function" ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isAnonymous")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\">\n"
    + ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"drawer",{"name":"ui","hash":{"class":"js-nav-menu-nav nav-menu-nav","autoFold":((stack1 = (depth0 != null ? lookupProperty(depth0,"navigation-menu") : depth0)) != null ? lookupProperty(stack1,"autoFoldOut") : stack1),"position":"left"},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":4},"end":{"line":30,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"navigation-menu") : depth0)) != null ? lookupProperty(stack1,"userMenu") : stack1),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":4},"end":{"line":38,"column":11}}})) != null ? stack1 : "")
    + "  </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    "
    + container.escapeExpression((lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||container.hooks.helperMissing).call(alias1,"drawer/dimmer",{"name":"ui","hash":{"class":"js-nav-menu-nav-dimmer","autoFold":((stack1 = (depth0 != null ? lookupProperty(depth0,"navigation-menu") : depth0)) != null ? lookupProperty(stack1,"autoFoldOut") : stack1),"position":"left"},"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":3,"column":110}}}))
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"navigation-menu") : depth0)) != null ? lookupProperty(stack1,"userMenu") : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":6,"column":11}}})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    "
    + container.escapeExpression((lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"drawer/dimmer",{"name":"ui","hash":{"class":"js-nav-menu-user-dimmer","position":"right"},"data":data,"loc":{"start":{"line":5,"column":4},"end":{"line":5,"column":75}}}))
    + "\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " nav-menu-"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"navigation-menu") : depth0)) != null ? lookupProperty(stack1,"branding") : stack1), depth0));
},"7":function(container,depth0,helpers,partials,data) {
    return "nav-logged-out";
},"9":function(container,depth0,helpers,partials,data) {
    return "nav-logged-in";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <nav id=\"navigation-menu-main\" aria-label=\"Meny\" aria-hidden=\"true\" tabindex=\"0\" class=\"navigation-menu-main\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"components-components-navigation_menu_v2-navigation_list"),depth0,{"name":"components-components-navigation_menu_v2-navigation_list","hash":{"autoFoldOut":((stack1 = (depth0 != null ? lookupProperty(depth0,"navigation-menu") : depth0)) != null ? lookupProperty(stack1,"autoFoldOut") : stack1),"menuItems":((stack1 = (depth0 != null ? lookupProperty(depth0,"navigation-menu") : depth0)) != null ? lookupProperty(stack1,"items") : stack1),"menuLevel":0},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"navigation-menu") : depth0)) != null ? lookupProperty(stack1,"infoText") : stack1),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":8},"end":{"line":17,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"navigation-menu") : depth0)) != null ? lookupProperty(stack1,"showLegal") : stack1),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":8},"end":{"line":27,"column":15}}})) != null ? stack1 : "")
    + "        <div class=\"js-nav-menu-nav-tab-end\" tabindex=\"0\"></div>\n      </nav>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <div class=\"nav-info-text\">\n            "
    + ((stack1 = container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"navigation-menu") : depth0)) != null ? lookupProperty(stack1,"infoText") : stack1), depth0)) != null ? stack1 : "")
    + "\n          </div>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <div class=\"nav-legal-text\">\n"
    + ((stack1 = (lookupProperty(helpers,"tenant")||(depth0 && lookupProperty(depth0,"tenant"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"lb",{"name":"tenant","hash":{},"fn":container.program(15, data, 0),"inverse":container.program(17, data, 0),"data":data,"loc":{"start":{"line":21,"column":12},"end":{"line":25,"column":23}}})) != null ? stack1 : "")
    + "          </div>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              "
    + container.escapeExpression((lookupProperty(helpers,"lb-ui")||(depth0 && lookupProperty(depth0,"lb-ui"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"gambling_authority",{"name":"lb-ui","hash":{},"data":data,"loc":{"start":{"line":22,"column":14},"end":{"line":22,"column":44}}}))
    + "\n";
},"17":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              "
    + container.escapeExpression((lookupProperty(helpers,"tb-ui")||(depth0 && lookupProperty(depth0,"tb-ui"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"gambling_authority",{"name":"tb-ui","hash":{},"data":data,"loc":{"start":{"line":24,"column":14},"end":{"line":24,"column":44}}}))
    + "\n";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"drawer",{"name":"ui","hash":{"class":"js-nav-menu-user nav-menu-user","autoFold":((stack1 = (depth0 != null ? lookupProperty(depth0,"navigation-menu") : depth0)) != null ? lookupProperty(stack1,"autoFoldOut") : stack1),"position":"right"},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":6},"end":{"line":37,"column":13}}})) != null ? stack1 : "");
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "        <nav id=\"navigation-menu-user\" aria-label=\"";
  stack1 = ((helper = (helper = lookupProperty(helpers,"isAnonymous") || (depth0 != null ? lookupProperty(depth0,"isAnonymous") : depth0)) != null ? helper : container.hooks.helperMissing),(options={"name":"isAnonymous","hash":{},"fn":container.program(21, data, 0),"inverse":container.program(23, data, 0),"data":data,"loc":{"start":{"line":33,"column":51},"end":{"line":33,"column":112}}}),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),options) : helper));
  if (!lookupProperty(helpers,"isAnonymous")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\" aria-hidden=\"true\" tabindex=\"0\" class=\"navigation-menu-user\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"components-components-navigation_menu_v2-navigation_list"),depth0,{"name":"components-components-navigation_menu_v2-navigation_list","hash":{"autoFoldOut":((stack1 = (depth0 != null ? lookupProperty(depth0,"navigation-menu") : depth0)) != null ? lookupProperty(stack1,"autoFoldOut") : stack1),"menuItems":((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"navigation-menu") : depth0)) != null ? lookupProperty(stack1,"userMenu") : stack1)) != null ? lookupProperty(stack1,"items") : stack1),"menuLevel":0},"data":data,"indent":"          ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </nav>\n        <div class=\"js-nav-menu-user-tab-end\" tabindex=\"0\"></div>\n";
},"21":function(container,depth0,helpers,partials,data) {
    return "Logga in";
},"23":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"firstName") || (depth0 != null ? lookupProperty(depth0,"firstName") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"firstName","hash":{},"data":data,"loc":{"start":{"line":33,"column":83},"end":{"line":33,"column":96}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"hide") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":40,"column":11}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true});
Handlebars.partials['components-components-navigation_menu_v2-navigation'] = svs.components.navigation_menu_v2.templates.navigation;
})(svs, Handlebars);


 })(window);